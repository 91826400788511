import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스마트폰은 갤럭시 최신 기종으로, 요금은 합리적인 알뜰폰 요금제로",
  "| 핀다이렉트샵, 삼성X핀다이렉트샵 자급제몰 통해 자급제폰 구매 시 가입 가능한 알뜰폰 요금제 2종 출시",
  "| 오픈이벤트로 전 구매고객 대상 풍성한 상품권 증정 행사까지",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/17/CMXXHfiP.png" alt="Figure1" />
      </div>
      <div className="desc">
        △스테이지파이브가 17일(화) 삼성전자판매와 함께 삼성X핀다이렉트샵 자급제몰에서
        <br />
        최신 자급제폰 구매고객을 대상으로 특별 알뜰폰 요금제를 선보인다
      </div>
    </div>
    <p>
      <br />
      카카오 계열의 통신사업자인 스테이지파이브가 삼성전자판매와 함께 삼성X핀다이렉트샵 자급제몰
      (https://www.samsungebiz.com/event/stage5)에서 최신 자급제폰 구매고객을 대상으로 특별 알뜰폰 요금제를 선보인다.
      <br />
      <br />
      해당 요금제는 1만원대의 실속 요금제와 3만원대의 데이터 무제한 요금제 2종으로 구성되어 있으며, 가입 및 개통 시 각각 풍성한
      상품권 혜택도 제공한다. 양사간의 제휴는 최근 급격히 늘고 있는 자급제폰 구매자들이 보다 합리적인 금액으로 통신라이프를 즐길
      수 있도록 선택의 폭을 넓혔다는데 의미가 있다.
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/17/knXfhrbh.jpeg" alt="Figure1" />
        </div>
        <div className="desc">
          △스테이지파이브가 17일(화) 삼성전자판매와 함께 삼성X핀다이렉트샵 자급제몰에서 <br />
          최신 자급제폰 구매고객을 대상으로 특별 알뜰폰 요금제를 선보인다. 프로모션 요금제 스펙.
        </div>
      </div>
      <br />
      각 요금제를 상세히 살펴보면 다음과 같다. 우선 ‘완전자유 뭐 이런걸 다 11GB+ (기프티쇼)’요금제는 데이터 이용량이 많은 고객
      맞춤형이다. 월 37,000원에 매일 LTE 기본데이터 11GB에 모두 소진하면 일 2GB, 월 150GB를 추가 지급한다. 고객이 매 월 받을 수
      있는 데이터양은 최대 221GB이며, 모두 소진 시 3Mbps의 속도로 계속 이용할 수 있다. 실질적 무제한인 셈이다. 물론 문자와 통화도
      무제한이다. 보통 데이터/음성/문자 사용량만 기본으로 주어지는 타 알뜰폰 요금제와 달리, 해당 요금제를 선택하면 매월 5,000원
      상당의 기프티쇼도 함께 받을 수 있다. (최대 24개월간, 12만원 상당) ‘완전자유 실속 1GB+’요금제는 1만원대의 합리적인 가격으로
      매일 7GB의 데이터를 즐길 수 있다. 19,000원의 요금을 내면 기본 데이터 1GB에 매월 추가데이터 6GB를 평생 받을 수 있으며, 데이터
      모두 소진 시 1Mbps의 속도로 이용이 가능하다. 두 요금제 모두 약정과 가입비, 배송비가 없다. 여기에 더해 유심비용까지 무료인
      것도 장점이다.
      <br />
      <br />
      요금제 오픈을 기념하여 신세계 상품권을 받을 수 있는 개통이벤트도 진행한다. 삼성X핀다이렉트샵 자급제몰의 특별 알뜰폰 요금제가
      궁금하다면, https://www.samsungebiz.com/event/stage5에서 확인이 가능하다.
      <br />
      <br />
      해당 요금제 제휴를 진행한 스테이지파이브 관계자는 “갤럭시폴드3 언팩에 맞춰 다양한 혜택의 알뜰폰 요금제를 구성하여 고객들에게
      합리적인 선택지를 드리려고 애썼다”고 전했다. 스테이지파이브는 최근 신한, 멜론, 카카오모빌리티 유저들을 위한 맞춤형
      제휴요금제를 연달아 출시하는 등, 지속적이고 공격적인 제휴처 확대에 나서고 있다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press15 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 8월 17일 즉시 배포 가능"
      pageInfo="*총 2매/ 첨부: 사진 2매"
      title="“갤럭시폴드3 쓰세요? 꿀조합인 알뜰폰 요금제 추천드려요” <br />핀다이렉트샵, 삼성전자 자급제폰 구매자 대상 제휴요금제 선보여"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press15
